import React from 'react';
import { Nav, Container } from 'react-bootstrap';

export default function NoMatch() {
  return (
    <div>
      <Container className='jumbo'>
        <h1 className='noPage'>Oops... No Page Found !!</h1>
        <h4 className='noPage'>
          Lets Go back
          <Nav.Link style={{ textDecoration: 'none', color: 'red' }} href='/'>
            Home
          </Nav.Link>
        </h4>
      </Container>
    </div>
  );
}
