import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

export default function TopBar() {
  return (
    <Navbar className='nav' expand='lg'>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='navLink '>
          <Nav.Link className='linkId' href='/'>
            Home
          </Nav.Link>
          <Nav.Link className='linkId' href='/aboutus'>
            About Us
          </Nav.Link>
          <Nav.Link className='linkId' href='/products'>
            Products
          </Nav.Link>

          <Nav.Link className='linkId' href='/services'>
            Services
          </Nav.Link>
          <Nav.Link className='linkId' href='/contactus'>
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
