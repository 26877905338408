import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './Components/Layouts/TopBar';

import 'bootstrap/dist/css/bootstrap.min.css';

import './Components/Style/Style.css';
import Products from './Components/Layouts/Pages/Products';
import Home from './Components/Layouts/Pages/Home';
import About from './Components/Layouts/Pages/About';
import NoMatch from './Components/Layouts/Pages/NoMatch';
import Contact from './Components/Layouts/Pages/Contact';
import { Layouts } from './Components/Layouts/Pages/Layouts';
import { NavigationBar } from './Components/Layouts/Pages/NavigationBar';
import Services from './Components/Layouts/Pages/Services';
import Footer from './Components/Layouts/Pages/Footer';

function App() {
  return (
    <>
      <NavigationBar />
      <TopBar />
      <Layouts>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/aboutus' element={<About />} />
            <Route path='/products' element={<Products />} />
            <Route path='/contactus' element={<Contact />} />
            <Route path='/services' element={<Services />} />
            <Route path='*' element={<NoMatch />} />
          </Routes>
        </Router>
      </Layouts>
      <Footer />
    </>
  );
}

export default App;
