import React from 'react';
import { Container } from 'react-bootstrap';

export default function Services() {
  return (
    <div className='mainContainer'>
      <img
        className='allImg'
        src={require('../../assets/services.jpg')}
        alt='Services'
      />
      <Container className='jumbo'>
        <p>
          <h2 className='headerColor'>Sales and Suppliers Services</h2>
          <br />
          With <strong>Global Tuch Solutions</strong> on your side, you can
          finally say goodbye to unexpected costs, ensure your data’s security,
          communicate more effectively and reduce those day-to-day problems that
          slow down your business.
        </p>
        <hr className='hrcolor' />
        <p>
          <strong>Global Tuch Solutions</strong> will get the most out of your
          business IT with tailor-made technology solutions. We offer the
          services you need to streamline your business, ensuring your
          organization is always running at its full potential.
        </p>
      </Container>
    </div>
  );
}
