import React from 'react';
import { Container } from 'react-bootstrap';

export default function About() {
  return (
    <div className='mainContainer'>
      <img
        className='allImg'
        src={require('../../assets/aboutus.jpg')}
        alt='About Us'
      />
      <Container className='jumbo'>
        <p className='products-para'>
          <h3 className='headerColor'>Global Tuch Solutions</h3>
          <br />
          Global Tuch Solutions focus to provide a long term technology
          partnerships and solutions to our small to large scale enterprise
          companies. We understand our client's unique technology needs; we will
          provide high level services for each IT solutions. We believe we can
          provide cost effective service solutions. It will be allowing you to
          get the best from your technology investment. Global Tuch solutions is
          information technology and services company located in Riyadh, Kingdom
          of Saudi Arabia serving clients nationwide.
        </p>
        <p className='aboutHeadList' style={{ color: 'red' }}>
          <strong>
            "We have high qualified professional staffs team for each section
            they are specialized their field."
          </strong>
        </p>
        <hr className='hrcolor' />
        <p>
          Global Tuch Solutions had a long experience in this area through the
          latest technologies and accuracy in the work and distinguished service
          in addition to the trust and confidence enjoyed by the company with
          its customers. GTS do all types of projects for computer network,
          maintenance and service for all types of Pcs, Notebooks and Printers
          etc. In the field of security system especially for IP Cameras/CCTV,
          Digital video recording, Alarm System and Time Attendant system etc.
          Our goal is to exceed the expectations of every client by offering
          outstanding customer service, increased Flexibility, and greater
          value, thus optimizing system functionality and improving operation
          Efficiency.
        </p>
      </Container>
    </div>
  );
}
