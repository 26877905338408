import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';

export const NavigationBar = () => (
  <div className="navigationBar">
    <div className="row">
      <div className="col">
        <FontAwesomeIcon
          icon={faEnvelopeOpen}
          style={{ marginRight: '5px' }}
          className="super-crazy-colors"
        />
        <a className="para-left" href="mailto:sales@globaltuch.com">
          sales@globaltuch.com
        </a>
      </div>
      <div className="col">
        <FontAwesomeIcon
          icon={faPhoneSquare}
          style={{ marginRight: '5px' }}
          className="super-crazy-colors"
        />
        <a className="para-right" href="tel:(966) 011-4007543">
          (966) 011-4007543
        </a>
      </div>
    </div>
  </div>
);
