import React from 'react';
import Slider from '../Slider';
import { Container } from 'react-bootstrap';

export default function Home() {
  return (
    <div className='main-outer-container' >
      {/* <img src={require('../../assets/pi4.jpg')} alt="placeholder" /> */}
      <Slider />
      <Container className='jumbo'>
        <h2 className='headerColor'>Welcome to Global Touch Solutions</h2>
        <br />
        <p className='intro'>
          Global Touch Solutions is an established local firm partnering with
          global brands to provide IT security and IT infrastructure solutions
          to clients. Global Touch offers flexible IT support plans and unique IT
          solutions using advanced cutting-edge technology to increase clients'
          productivity.
        </p>
      </Container>
    </div>
  );
}
