import React from 'react';
import { Container } from 'react-bootstrap';

export default function Products() {
  return (
    <div className='mainContainer'>
      <img
        className='allImg'
        src={require('../../assets/products.jpg')}
        alt='Products'
      />
      <Container className='jumbo'>
        <p className='products-para'>
          <h3 className='headerColor'>Global Tuch Solutions</h3>
          <br />
          Technology matters but what you do matters most. It is the right tools
          built for your unique users, environment, and mission. 
          At <strong>Global Tuch Solutions</strong>, we are here to help you
          focus on doing what you do best. Let the experts at <strong>Global Tuch Solutions</strong> listen to
          your needs, understand your goals, and deliver the product for you.  We have wide range of products to choose the best for you.
        </p>
        <p>
          <strong>Global Tuch Solutions</strong> is a full service provider of
          comprehensive, integrated information technology services and business
          solutions designed to fulfill the needs of local, regional and global
          enterprise companies. Global Tuch Solutions provides high-value IT
          solutions to a broad range of industries. Our products and solution
          offerings span across different areas as listed below
        </p>
        <hr className='hrcolor' />
        <h4 className='productsHeadList'>Our Products and Solutions</h4>
        <br />
        <ul className='productsList'>
          <li>Desktop Computers</li>
          <li>Laptop Computers</li>
          <li>Printers & Plotters</li>
          <li>Toners & Cartriges</li>
          <li>Operating Systems</li>
          <li>IT Security Solutions</li>
          <li>CCTV Solutions</li>
          <li>Servers & Workstations</li>
          <li>SAP Services</li>
          <li>Software & Custom Software Development</li>
          <li>Serveral other Parts and Accessories</li>
        </ul>
      </Container>
    </div>
  );
}
